import { render, staticRenderFns } from "./pointsManager.vue?vue&type=template&id=830253fc&scoped=true&"
import script from "./pointsManager.vue?vue&type=script&lang=js&"
export * from "./pointsManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830253fc",
  null
  
)

export default component.exports